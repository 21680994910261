<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">公司客户</div>
				<div class="header-button">
					<el-button
						v-permission="'pc/driver-center/company-customer/add'"
						type="success"
						@click="onOpenDialog('create')"
					>
						<el-icon class="icon-plus"><Plus /></el-icon>
						新增
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="settingRef" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					show-no
					:columns="columnRef"
					:data="tableData"
					:params="{ page }"
					autoWidth
				>
					<template #companyStatus="{ value, row }">
						<span>{{ mapDict('company_customer_status', value) }}</span>
					</template>
					<template #action>
						<el-table-column width="150" label="操作" fixed="right">
							<template #default="{ row }">
								<el-link
									v-permission="'pc/driver-center/company-customer/edit'"
									type="primary"
									style="margin-right: 15px"
									:underline="false"
									@click="onOpenDialog('update', row)"
								>
									编辑
								</el-link>
							</template>
						</el-table-column>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<form-dialog
			ref="formDialogRef"
			:fuel-type-options="fuelTypeOptions"
			@refresh="onRefreshData"
		/>
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'CompanyCustomer',
})
</script>

<script setup name="CompanyCustomer">
import { ref, inject, getCurrentInstance, onActivated, onMounted } from 'vue'
import { filters, columns } from './config'
import { useState, useDict } from '@/utils/hooks'
import Request from '@/http'
import usePage from '@/hooks/usePage'
import FormDialog from './components/form'

const settingRef = ref(filters)
const columnRef = ref(columns)
const formDialogRef = ref(null)
const instance = getCurrentInstance()
const $message = inject('$message')

const [dict, setDict] = useState({})

function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}

const {
	isLoading,
	filterParams,
	totalCount,
	page,
	tableData,
	onChangeFilter,
	onSizeChange,
	onPageChange,
	onRefreshData,
} = usePage(getCompanyCustomerList)

async function getCompanyCustomerList() {
	isLoading.value = true
	const params = {
		...filterParams.value,
		page: page.value,
	}

	Request({
		url: '/suf4-asset-service/company/customer/info/page',
		method: 'POST',
		data: params,
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				tableData.value = res.data.records
				totalCount.value = res.data.total
				instance.refs.yiTableRef.scrollTop()
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

async function getStatusDict() {
	const dict = await useDict('company_customer_status')
	if (dict) setDict(dict)
	const status = findItem('status')
	status.options = dict.company_customer_status
}

function init() {
	getStatusDict()
	onRefreshData()
}

function findItem(key) {
	return settingRef.value.filters.find(item => item.attr === key)
}

function onOpenDialog(type, item) {
	if (type === 'update') {
		const data = {
			...item,
			companyStatus: item.companyStatus + '',
		}
		instance.refs.formDialogRef.update(data)
	} else {
		instance.refs.formDialogRef.create()
	}
}

// onActivated(() => {
// 	init()
// })
onMounted(() => {
	init()
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
	.cursor {
		cursor: pointer;
		color: #409eff;
	}
}
</style>

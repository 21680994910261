<template>
	<el-dialog
		v-model="isShowDialog"
		width="700px"
		class="dialog-container"
		:title="dialogTitleComp"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="dialog-body" v-loading="isLoading">
			<el-form
				ref="ruleFormRef"
				label-width="150px"
				size="large"
				:model="formData"
				:rules="formRule"
			>
				<el-row>
					<el-col :span="18">
						<el-form-item label="公司名称" prop="companyName">
							<el-input
								v-model="formData.companyName"
								placeholder="请输入公司名称"
								:maxlength="20"
								:disabled="formType === 'create' ? false : true"
								show-word-limit
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="公司电话" prop="companyPhone">
							<el-input
								v-model="formData.companyPhone"
								placeholder="请输入公司电话"
								:maxlength="20"
								show-word-limit
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="所属公司" prop="affiliateCompanyIds">
							<el-select
								v-model="formData.affiliateCompanyIds"
								placeholder="请选择所属公司"
								style="width: 100%"
								filterable
								multiple
							>
								<el-option
									v-for="(item, index) in companyOptions"
									:key="index"
									:label="item.companyName"
									:value="item.companyId"
								/>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="联系人" prop="contactPerson">
							<el-input
								v-model="formData.contactPerson"
								placeholder="请输入联系人"
								:maxlength="20"
								show-word-limit
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="联系电话" prop="contactPersonPhone">
							<el-input
								v-model="formData.contactPersonPhone"
								placeholder="请输入联系电话"
								:maxlength="20"
								show-word-limit
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="公司地址" prop="companyAddress">
							<el-input
								v-model="formData.companyAddress"
								placeholder="请输入公司地址"
								:maxlength="100"
								show-word-limit
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="公司状态" prop="companyStatus">
							<el-radio-group v-model="formData.companyStatus">
								<el-radio v-for="(item, index) in statusOptions" :key="index" :label="item.code">
									{{ item.name }}
								</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" :disabled="isDisabled" @click="onSubmit">保 存</el-button>
				<el-button :disabled="isDisabled" @click="onClose">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { defineExpose, defineEmits, inject, reactive, getCurrentInstance, ref, computed } from 'vue'
import Request from '@/http'
import { useDict } from '@/utils/hooks'
import { rules } from '../config'

const isShowDialog = ref(false)
const isDisabled = ref(false)
const isLoading = ref(false)
const companyOptions = ref([])
const statusOptions = ref([])

const formInstance = getCurrentInstance()
const $message = inject('$message')

const formInitValue = {
	companyName: '',
	companyPhone: '',
	affiliateCompanyIds: [],
	contactPerson: '',
	contactPersonPhone: '',
	companyAddress: '',
	companyStatus: '',
}
const formData = reactive({})
const formRule = rules

const emit = defineEmits(['refresh'])

const formType = ref('create')
let waitUpdateId = ''

const dialogTitleComp = computed(() => {
	let dialogTitle = '新增公司客户'

	if (formType.value === 'update') {
		dialogTitle = '编辑公司客户'
	}

	return dialogTitle
})

function getCompanyList() {
	Request({
		url: '/suf4-user-service/company/select?isPermission=true&status=1',
		method: 'GET',
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				companyOptions.value = res.data
				formInstance.refs.ruleFormRef.clearValidate(['affiliateCompanyIds'])
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

async function getStatusDict() {
	const dict = await useDict('company_customer_status')
	statusOptions.value = dict.company_customer_status
}

function create() {
	isShowDialog.value = true
	formType.value = 'create'
	getCompanyList()
	getStatusDict()
}

async function update(item) {
	isShowDialog.value = true
	formType.value = 'update'
	waitUpdateId = item.id
	Object.assign(formData, item)
	getCompanyList()
	getStatusDict()
}

async function onSubmit() {
	try {
		const formValidRes = await formInstance.refs.ruleFormRef.validate()
		if (!formValidRes) throw new Error('表单校验失败, 请检查!')
		isLoading.value = true
		let requestUrl = ''
		const data = formData
		if (formType.value === 'create') {
			requestUrl = '/suf4-asset-service/company/customer/info/add'
		} else {
			requestUrl = '/suf4-asset-service/company/customer/info/edit'
			data.id = waitUpdateId
		}
		const res = await Request({
			url: requestUrl,
			method: 'POST',
			data,
		})
		if (res.code === 200) {
			isLoading.value = false
			$message.success(`${formType.value === 'create' ? '创建' : '更新'}成功!`)
			emit('refresh')
			onClose()
		} else {
			throw new Error(res.msg)
		}
	} catch (e) {
		isLoading.value = false
		$message.error(e.message || '表单校验失败, 请检查')
	}
}

function onClose() {
	Object.assign(formData, formInitValue)
	isShowDialog.value = false
}

defineExpose({ create, update })
</script>

<style lang="less">
.number-input-left {
	.el-input__inner {
		text-align: left;
	}
}
</style>
<style lang="less" scoped>
.unit-box {
	width: 40px;
	height: 40px;
	.flexible(row, center, center);
}

.short-box {
	width: 200px;
}

.brand-button {
	margin-left: 12px;
}
.dialog-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.dialog-body {
		width: 100%;
		overflow: hidden;
	}
}
</style>

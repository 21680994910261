import { telephoneAndMobileReg } from '@/utils/regUtil'

export const filters = {
	expand: false,
	filters: [
		{
			attr: 'keyword',
			type: 'search',
			label: '公司名称',
			width: 320,
			placeholder: '输入公司名称',
		},
		{
			attr: 'status',
			type: 'select',
			label: '状态',
			placeholder: '请选择状态',
			options: [],
		},
	],
}

export const columns = [
	{
		prop: 'companyStatus',
		label: '公司状态',
		width: '120px',
	},
	{
		prop: 'companyName',
		label: '公司名称',
		width: '280px',
	},
	{
		prop: 'companyPhone',
		label: '公司电话',
		width: '180px',
	},
	{
		prop: 'affiliateCompanyName',
		label: '所属公司',
		minWidth: '500px',
	},
	{
		prop: 'contactPerson',
		label: '联系人',
		width: '150px',
	},
	{
		prop: 'contactPersonPhone',
		label: '联系电话',
		width: '180px',
	},
	{
		prop: 'companyAddress',
		label: '公司地址',
		minWidth: '420px',
	},
]

export const rules = {
	companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
	companyPhone: [
		{ required: true, message: '请输入公司电话', trigger: 'blur' },
		{ pattern: telephoneAndMobileReg, message: '请输入正确的电话/手机号格式', trigger: 'blur' },
	],
	affiliateCompanyIds: [{ required: true, message: '请选择所属公司', trigger: 'change' }],
	contactPersonPhone: [
		{ pattern: telephoneAndMobileReg, message: '请输入正确的电话/手机号格式', trigger: 'blur' },
	],
	companyStatus: [{ required: true, message: '请选择公司状态', trigger: 'change' }],
}
